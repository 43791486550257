<template>
  <div class="page-container">
    <van-button @click="selectAddress" type="info" style="width:100%">点击此处选择地址</van-button>
    <van-cell-group title="我的地址">
      <template v-if="Object.keys(address).length">
        <van-cell title="姓名" :value="address.name" />
        <van-cell title="手机" :value="address.phone" />
        <van-cell title="省份" :value="address.province" />
        <van-cell title="城市" :value="address.city" />
        <van-cell title="地区" :value="address.country" />
        <van-cell title="详细" :value="address.detail" />
        <van-cell title="邮编" :value="address.postcode" />
      </template>
      <template v-else><van-empty description="暂无数据" /></template>
    </van-cell-group>
  </div>
</template>

<script>
import wxSdk from '@/utils/wxsdk'
import { Toast } from 'vant'
import { getMyAddressData, updateMyAddressData } from '@/api/mine'
export default {
  data () {
    return {
      address: {}
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getMyAddressData().then((response) => {
        this.address = response
      })
    },
    selectAddress () {
      const _this = this
      wxSdk
        .address()
        .then((res) => {
          let address = {
            name: res.userName,
            phone: res.telNumber,
            province: res.provinceName,
            city: res.cityName,
            country: res.countryName,
            detail: res.detailInfo,
            postcode: res.postalCode,
            nationcode: res.nationalCode
          }
          updateMyAddressData(address).then(() => {
            Toast.success('更新成功')
            _this.fetchData()
          })
        })
        .catch(() => {
          Toast.success('更新失败')
        })
    }
  }
}
</script>

<style>
</style>
